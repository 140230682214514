import { CARD_TYPE } from './constants';

export const cardNumberFormat = value => {
  const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  const matches = v.match(/\d{4,16}/g);
  const match = (matches && matches[0]) || '';
  const parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return value;
  }
};

export const getCardType = cardNum => {
  // accept only digits, dashes or spaces
  const numericDashRegex = /^[\d\-\s]+$/;
  if (!numericDashRegex.test(cardNum)) return '';

  let payCardType = 'DebitCard';
  /* var regexMap = [
    {regEx: /^4[0-9]{5}/ig,cardType: "VISA"},
    {regEx: /^5[1-5][0-9]{4}/ig,cardType: "MASTERCARD"},
    {regEx: /^3[47][0-9]{3}/ig,cardType: "AMEX"},
    {regEx: /^(5[06-8]\d{4}|6\d{5})/ig,cardType: "MAESTRO"}
  ];*/
  const regexMap = [
    { regEx: /^4/gi, cardType: CARD_TYPE.VISA },
    { regEx: /^5[1-5]/gi, cardType: CARD_TYPE.MASTERCARD },
    { regEx: /^3[47]/gi, cardType: CARD_TYPE.AMEX },
    { regEx: /^(5[06-8]\d{4}|6\d{5})/gi, cardType: CARD_TYPE.MAESTRO },
  ];
  for (let j = 0; j < regexMap.length; j++) {
    if (cardNum.match(regexMap[j].regEx)) {
      payCardType = regexMap[j].cardType;
      break;
    }
  }
  if (
    cardNum.indexOf('50') === 0 ||
    cardNum.indexOf('60') === 0 ||
    cardNum.indexOf('65') === 0
  ) {
    const g = '508500-508999|606985-607984|608001-608500|652150-653149';
    const i = g.split('|');
    for (let d = 0; d < i.length; d++) {
      const c = parseInt(i[d].split('-')[0], 10);
      const f = parseInt(i[d].split('-')[1], 10);
      if (
        cardNum.substr(0, 6) >= c &&
        cardNum.substr(0, 6) <= f &&
        cardNum.length >= 6
      ) {
        payCardType = 'RUPAY';
        break;
      }
    }
  }
  return payCardType;
};

export const isCardValid = cardNum => {
  // accept only digits, dashes or spaces
  const numericDashRegex = /^[\d\-\s]+$/;
  if (!numericDashRegex.test(cardNum)) return false;

  // The Luhn Algorithm.
  let nCheck = 0;
  let nDigit = 0;
  let bEven = false;
  const strippedField = cardNum.replace(/\D/g, '');
  for (let n = strippedField.length - 1; n >= 0; n--) {
    const cDigit = strippedField.charAt(n);
    nDigit = parseInt(cDigit, 10);
    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }
    nCheck += nDigit;
    bEven = !bEven;
  }
  if (nCheck % 10 === 0) return true;
  return false;
};

export const formatExpiry = event => {
  const eventData = event.nativeEvent && event.nativeEvent.data;
  const prevExpiry = event.target.value.split(' / ').join('/');

  if (!prevExpiry) return null;
  let expiry = prevExpiry;
  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`;
  }

  if (prevExpiry.length === 2 && +prevExpiry > 12) {
    const [head, ...tail] = prevExpiry;
    expiry = `0${head}/${tail.join('')}`;
  }

  if (/^1[/-]$/.test(expiry)) {
    return `01 / `;
  }

  expiry = expiry.match(/(\d{1,2})/g) || [];
  if (expiry.length === 1) {
    if (!eventData && prevExpiry.includes('/')) {
      return expiry[0];
    }
    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `;
    }
  }
  if (expiry.length > 2) {
    const [, month, year] = expiry.join('').match(/^(\d{2}).*(\d{2})$/) || [];
    return [month, year].join(' / ');
  }
  return expiry.join(' / ');
};

export const formatCvc = cvc => {
  return (cvc.match(/\d+/g) || []).join('');
};

const ERROR_TEXT__INVALID_EXPIRY_DATE = 'Expiry date is invalid';
const ERROR_TEXT__MONTH_OUT_OF_RANGE = 'Expiry month must be between 01 and 12';
const ERROR_TEXT__YEAR_OUT_OF_RANGE = 'Expiry year cannot be in the past';

const EXPIRY_DATE_REGEX = /^(\d{2})\/(\d{4}|\d{2})$/;
const MONTH_REGEX = /(0[1-9]|1[0-2])/;

export const isExpiryInvalid = expiryDate => {
  const splitDate = expiryDate.split('/');
  if (!EXPIRY_DATE_REGEX.test(expiryDate)) {
    return ERROR_TEXT__INVALID_EXPIRY_DATE;
  }

  const expiryMonth = splitDate[0];
  if (!MONTH_REGEX.test(expiryMonth)) {
    return ERROR_TEXT__MONTH_OUT_OF_RANGE;
  }

  const expiryYear = splitDate[1];
  let currentYear = new Date().getFullYear();
  currentYear = parseInt(
    expiryYear.length === 4 ? currentYear : currentYear.toString().substr(-2),
    10
  );
  if (currentYear > parseInt(expiryYear, 10)) {
    return ERROR_TEXT__YEAR_OUT_OF_RANGE;
  }

  return false;
};
