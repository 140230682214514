import store from 'src/redux/store';
import { PATH } from './routes';
import { NEW_STORE_WEBSITE_STATUS } from 'src/constants/subscription';
import { toastNotifyError } from 'src/components/Common/Toast';

export const canDisablePlaceOrder = (
  args = {
    showMessage: true,
    message: 'This is a demo website, you need a live version to proceed this action.',
  }
) => {
  const { showMessage, message } = args;
  const storeInfo = store.getState()?.storeReducer?.store;

  // Enable place order flow for stores having theme and staging environment.
  if (process.env.NEXT_PUBLIC_CUSTOM_ENV !== 'PROD' || !!storeInfo?.premium) {
    return false;
  }

  const disablePlaceOrder =
    window.location.pathname?.includes(PATH.BUILDER_PREVIEW) ||
    // for new stores
    storeInfo?.theme?.new_subscription_web_status ===
      NEW_STORE_WEBSITE_STATUS.ACTIVE_NOT_LIVE ||
    // for old stores
    storeInfo?.domain_info?.domain_type === 0;

  if (disablePlaceOrder && showMessage && message?.trim()) {
    toastNotifyError(message);
  }

  return disablePlaceOrder;
};
