/**
 * Card Payment Input Component
 */
import { CARD_TYPE, PAYMENT_TYPE_CONSTANTS } from '@/utils/constants';
import { getDeviceInfo } from '@/utils/deviceInfo';
import { getEncrypDecryptStr } from '@/utils/encryptionDecryption';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkCardInfo,
  fetchPaymentMethods,
  initPayment,
  prePaymentCheckMDRTransactions,
  setCustomerTransactionPaymentMethod,
} from 'src/redux/actions';
import config from '../../config';
import {
  cardNumberFormat,
  formatCvc,
  formatExpiry,
  getCardType,
  isCardValid,
  isExpiryInvalid,
} from '../../utils/cardTypeValidator';
import { cc_icons } from '@/utils/constants';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';

const CardPaymentBox = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [state, setState] = useState({
    cvv: '',
    expiry: '',
    number: '',
    cardType: '',
    isCardNumberValid: true,
    isCardMonthInvalid: false,
    cvvLength: 3,
  });

  const {
    store,
    orderDetails,
    loader,
    storeData,
    paymentOptionsWithTxnCharge,
    userData,
  } = useSelector((state) => ({
    store: state.storeReducer.store,
    orderDetails: state.orderReducer.orderDetails,
    loader: state.commonReducer.loader,
    storeData: state.storeReducer.store,
    paymentOptionsWithTxnCharge: state.paymentReducer.paymentOptionsWithTxnCharge,
    userData: state.userReducer.data,
  }));

  /**
   * UseEffect for prefilled card data
   */
  useEffect(() => {
    store?.store_id &&
      orderDetails?.order_hash &&
      dispatch(
        fetchPaymentMethods({
          store_id: store?.store_id,
          hash: orderDetails?.order_hash,
        })
      );
    let newState = { ...state };
    if (localStorage?.getItem('dscc')) {
      const cardDetails = JSON.parse(
        getEncrypDecryptStr(JSON.parse(localStorage?.getItem('dscc')), 'decrypt')
      );
      newState = {
        ...state,
        cvv: formatCvc(cardDetails.cCvv),
        expiry: cardDetails.cExpMonth + '/' + cardDetails.cExpYear.slice(-2),
        number: cardNumberFormat(cardDetails.cNum),
        cardType: getCardType(cardDetails.cNum),
        cvvLength: getCardType(cardDetails.cNum) === 'AMEX' ? 4 : 3,
      };
    }
    setState(newState);
  }, []);

  const handleInputChange = (key, value, event) => {
    const newState = { ...state };
    if (key === 'number') {
      newState[key] = cardNumberFormat(value);
      newState['isCardNumberValid'] = isCardValid(value);
      newState['cardType'] = getCardType(value);
      newState['cvvLength'] = newState['cardType'] === CARD_TYPE.AMEX ? 4 : 3;
    } else if (key === 'expiry') {
      newState['expiry'] = formatExpiry(event);
    } else if (key === 'cvv') {
      newState['cvv'] = formatCvc(value);
    }
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  /**
   * this function is written to check the exact card type in pascal,
   * as required by the enterprise team for the mdr check api
   * @param {*} cardInfo
   * @return {string}
   */
  const returnCardTypeString = (cardInfo) => {
    if (
      cardInfo.card_type === CARD_TYPE.AMEX &&
      cardInfo.category === PAYMENT_TYPE_CONSTANTS.CREDIT_CARD.toLowerCase()
    ) {
      return PAYMENT_TYPE_CONSTANTS.AMEX_CARD;
    } else {
      switch (cardInfo.category) {
        case PAYMENT_TYPE_CONSTANTS.DEBIT_CARD.toLowerCase():
          return PAYMENT_TYPE_CONSTANTS.DEBIT_CARD;
        case PAYMENT_TYPE_CONSTANTS.CREDIT_CARD.toLowerCase():
          return PAYMENT_TYPE_CONSTANTS.CREDIT_CARD;
        default:
          return '';
      }
    }
  };

  const callForSpecificCardDetails = (value, callback) => {
    dispatch(
      checkCardInfo(value, (status, data) => {
        if (status && !!data) {
          if (data?.card_type === CARD_TYPE.AMEX) {
            const { payment_modes } = paymentOptionsWithTxnCharge;
            if (payment_modes?.length) {
              const amexCardData = payment_modes?.find(
                (payment) =>
                  payment.payment_option_name === PAYMENT_TYPE_CONSTANTS.AMEX_CARD
              );

              dispatch(setCustomerTransactionPaymentMethod(amexCardData));
            }
          }
          const payload = {
            order_hash: orderDetails?.order_hash,
            store_id: orderDetails?.store_id,
            payment_option: {
              payment_option_name: returnCardTypeString(data),
            },
          };
          dispatch(prePaymentCheckMDRTransactions(payload, callback));
        }
      })
    );
  };

  const validateAll = () => {
    const newState = { ...state };
    let isAllValid = true;
    if (!state.number) {
      newState['isCardNumberValid'] = false;
      isAllValid = false;
    }
    if (!state.expiry) {
      newState['isCardMonthInvalid'] = 'Enter a valid expiry';
      isAllValid = false;
    }
    if (state.cvvLength !== state.cvv.length) {
      newState['isCardCVVInvalid'] = 'Enter a valid CVV';
      isAllValid = false;
    }
    setState(newState);
    return isAllValid;
  };

  const validateCardNumber = (number) => {
    const newState = { ...state };
    newState['isCardNumberValid'] = isCardValid(number);
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));

    if (storeData?.services?.mdr_flag && number.split(' ').join('').length >= 6) {
      const cardNo = newState['number'].trim().split(' ').join('').substring(0, 6);
      callForSpecificCardDetails(cardNo);
    }
  };

  const validateCardMonth = (number) => {
    const newState = { ...state };
    newState['isCardMonthInvalid'] = isExpiryInvalid(number);
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const validateCardCVV = (number) => {
    const newState = { ...state };
    newState['isCardCVVInvalid'] =
      number.length !== state.cvvLength ? 'Enter valid CVV' : false;
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const checkForValidCardLogic = () => {
    return (
      state.number?.split(' ')?.join('').length >= 14 &&
      state.isCardNumberValid &&
      !state.isCardMonthInvalid
    );
  };

  const onPayBtnClick = () => {
    if (canDisablePlaceOrder()) {
      return;
    }
    if (validateAll() && checkForValidCardLogic()) {
      const data = {
        orderID: orderDetails?.order_hash,
        phone: userData?.phone,
        paymentMethod: 'card',
        merchantID: orderDetails?.merchant_id,
        deviceType: getDeviceInfo().OSName,
        browserType: getDeviceInfo().BrowserName,
        userAgent: getDeviceInfo().userAgent,
        source: 'longtail',
        cNum: state.number.split(' ').join(''),
        cCvv: state.cvv,
        cName: 'Dotpe',
        cExpMonth: state.expiry.split('/')[0].trim(),
        cExpYear: '20' + state.expiry.split('/')[1].trim(),
        merchantName: `Digital Showroom - ${storeData?.store_info?.name}`,
      };
      const encryptCCDetails = getEncrypDecryptStr(JSON.stringify(data), 'encrypt');
      localStorage?.setItem('dscc', JSON.stringify(encryptCCDetails));
      if (props.isVerifyCard) {
        props.onVerifyCard(data);
      } else {
        if (storeData?.services?.mdr_flag) {
          callForSpecificCardDetails(
            state.number.trim().split(' ').join('').substring(0, 6),
            () => {
              dispatch(initPayment(data, router));
            }
          );
        } else {
          dispatch(initPayment(data, router));
        }
      }

      /**
       * This was added as to fix bank redirection after confirming OTP on our temporary domains, as the redirection
       * only happens on domain i.e. showroom.dotpe.in & not on our path -> showroom.dotpe.in/${domain},
       * so redirection details are saved here.
       */
      if (
        storeData?.store_info?.domain &&
        storeData?.store_info?.domain?.startsWith('d-temporary')
      ) {
        sessionStorage.setItem(
          'cardPaymentDetails',
          JSON.stringify({
            paymentMethod: 'card',
            redirectionUrl: storeData?.store_info?.store_url,
            storeDomain: storeData?.store_info?.domain,
          })
        );
      }
    }
  };

  function getCtaBtnText() {
    return props.isVerifyCard ? 'VERIFY NOW' : 'Pay';
  }

  return (
    <section className="card-payment-box">
      <div className="card-wrapper">
        <div className="card">
          <div className="top-wrap">
            <div className="icon-wrapper">
              <img
                loading="lazy"
                src={
                  cc_icons[state.cardType] ||
                  config.CDN_SERVER_URL + 'image/ic-credit-card.svg'
                }
                alt="cardType"
              />
            </div>
          </div>
          <div className="form-wrap">
            <div className="form-group">
              <label>Card Number</label>
              <input
                type="text"
                inputMode="numeric"
                className="form-control"
                placeholder="XXXX XXXX XXXX XXXX"
                onChange={(e) => handleInputChange('number', e.target.value)}
                onBlur={() => validateCardNumber(state.number)}
                value={state.number}
                pattern="[0-9.]+"
              />
              {!state.isCardNumberValid && (
                <small className="error">Enter valid card number</small>
              )}
            </div>
            <div className="inline-form-wrap">
              <div className="form-group">
                <label>Expires</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="MM/YY"
                  value={state.expiry}
                  pattern="\d*"
                  maxLength="7"
                  onChange={(e) => handleInputChange('expiry', e.target.value, e)}
                  onBlur={() =>
                    state?.expiry && validateCardMonth(state?.expiry?.split(' ').join(''))
                  }
                />
                {state.isCardMonthInvalid && (
                  <small className="error">{state.isCardMonthInvalid}</small>
                )}
              </div>
              <div className="form-group">
                <label>CVV</label>
                <input
                  type="password"
                  value={state.cvv}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  className="form-control"
                  placeholder={state.cvvLength === 4 ? '* * * *' : '* * *'}
                  maxLength={state.cvvLength}
                  onChange={(e) => handleInputChange('cvv', e.target.value)}
                  onBlur={(e) => validateCardCVV(state.cvv)}
                />
                {state.isCardCVVInvalid && (
                  <small className="error">{state.isCardCVVInvalid}</small>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={onPayBtnClick}
          className={`cta-btn ${
            state.number?.split(' ')?.join('').length < 14 ? 'o-50' : ''
          } ${checkForValidCardLogic() ? `o-100 pointer` : `o-50`}`}
          disabled={loader || !checkForValidCardLogic()}
        >
          {!loader ? (
            getCtaBtnText()
          ) : (
            <img src={'/assets/images/ellipLoader1.gif'} className="img h1" />
          )}
        </button>
      </div>
    </section>
  );
};

export default CardPaymentBox;
